import {
    updateConfigurations,
    getConfigurations,
  } from "../../../api/hr-settings/conf";
  const actions = {
    getConfigurations({ commit }) {
      return new Promise((resolve, reject) => {
        getConfigurations()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateConfigurations({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateConfigurations(data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  
  };
  
  export default {
    namespaced: true,
    actions,
  };
  