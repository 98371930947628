// new-branch routes
const getNewBranch = () => import("../../../pages/hr-settings/new-branch");
const createNewBranch = () =>
  import("../../../pages/hr-settings/new-branch/create-branch.vue");
const viewNewBranch = () =>
  import("../../../pages/hr-settings/new-branch/update-branch.vue");

import Body from "../../../components/body";
const newBranch = {
  path: "/branch",
  component: Body,
  meta: { title: "Branch", roles: ["hr", "admin"] },
  children: [
    // new-branch
    {
      path: "",
      name: "/new-branchs-index",
      component: getNewBranch,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/create-branch",
      name: "create-branch",
      component: createNewBranch,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/branch-view/:id",
      name: "branch-view",
      meta: { requiredAuth: true ,roles: ["hr", "admin"]},
      component: viewNewBranch,
    },
  ],
};

export default newBranch;
