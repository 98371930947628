import request from "../../utils/request";
export function getConfigurations() {
  return request({
    url: "/setting",
    method: "GET",
  });
}

export function updateConfigurations(data) {
  return request({
    url: "/setting",
    method: "patch",
    data
  });
}

