import request from "../utils/request";

// get logged in user info
export function getInfo() {
  return request({
    url: "/users/profile",
    method: "get",
  });
}

export function viewUsers(data) {
  return request({
    url: "/users/",
    method: "GET",
    data,
  });
}
export function getUsersWithPagination(query) {
  return request({
    url: "/users/list",
    method: "GET",
    params: query
  });
}
export function viewUserRole(data) {
  return request({
    url: "/role",
    method: "GET",
    data,
  });
}
export function getUserById(user_id) {
  return request({
    url: "/users/" + user_id,
    method: "get",
  });
}
export function search(item) {
  return request({
    url: "/users/search/" + item.data + "/page/" + item.page,
    method: "get",
  });
}
export function deleteUserById(user_id) {
  return request({
    url: "/users/" + user_id,
    method: "delete",
  });
}
export function createUser(data) {
  return request({
    url: "/users",
    method: "POST",
    data
  })
}

export function uploadImage(data) {
  return request({
    url: "/users/upload_image/" + data.idNo,
    method: "POST",
    data: {
      profile: data.profile,
      signature: data.signature,
    },
  });
}
export function updateUser(data) {
  return request({
    url: "/users/" + data.idNo,
    method: "put",
    data
  });
}

export function removeUserDept(id){
  return request({
    url: "/area/remove/" + id,
    method: "patch",
  });
}

export function transferUserDept(data){
  return request({
    url: "/area/transfer",
    method: "post",
    data
  });
}

export function assignUserRole(data) {
  return request({
    url: "/role/assignToUser/" + data.id,
    method: "post",
    data: {
      role: data.role,
    },
  });
}
export function deleteRole(data) {
  return request({
    url: "/role/removeFromUser/" + data.id,
    method: "post",
    data: {
      role: data.role,
    },
  });
}
export function viewUserLogs(data) {
  return request({
    url: "/access_log",
    method: "GET",
    data,
  });
}
export function viewUserLogsPagination(page) {
  return request({
    url: "/access_log/page/" + page,
    method: "GET",
  });
}

export function notification(user_id) {
  return request({
    url: "/notification/" + user_id,
    method: "get",
  });
}
export function notificationSeen(id) {
  return request({
    url: "/notification/" + id,
    method: "put",
  });
}

export function getUserHelperData() {
  return request({
    url: "/users/helper",
    method: "get",
  });
}

export function updateDepartment(data) {
  return request({
    url: "/area/update",
    method: "patch",
    data: data,
  });
}
