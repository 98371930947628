export var menuItems = {
  data: [
    {
      path: "/",
      title: "ড্যাশবোর্ড",
      icon: "Dashboard",
      iconf: "fill-board",
      type: "link",
      active: false,
      meta: {
        title: "Dashboard",
        roles: ["admin", "dosser", "user", "hr", "role", "iro", "cro", "access_log", "applicant", "reporter assign"],
      },
    },
    {
      path: "/employee-hierarchy",
      title: "জনবল কাঠামো",
      icon: "Dashboard",
      iconf: "fill-board",
      type: "link",
      active: false,
      meta: {
        title: "Employee Hierarchy",
        roles: ["dosser", "admin", "reporter assign"],
      },
    },
    
    {
      title: "ডোসিয়ার",
      icon: "APPROVEDLIST",
      iconf: "fill-users",
      type: "sub",
      active: false,
      meta: {
        title: "Dossier",
        roles: ["dosser"],
      },
      children: [
        // {
        //   path: "/dossier/reporters",
        //   title: "প্রতিবেদনকারী",
        //   type: "link",
        //   active: false,
        //   meta: {
        //     title: "Dossier",
        //     roles: ["dosser"],
        //   },
        // },
        {
          path: "/dossier/pending-acr",
          title: "অপেক্ষমান এসিআর",
          type: "link",
          active: false,
          meta: {
            title: "Dossier",
            roles: ["dosser"],
          },
        },
        {
          path: "/dossier/evaluated-acr",
          title: "মুল্যায়িত এসিআর",
          type: "link",
          active: false,
          meta: {
            title: "Dossier",
            roles: ["dosser"],
          },
        },
        {
          path: "/dossier/report",
          title: "রিপোর্ট",
          type: "link",
          active: false,
          meta: {
            title: "Dossier",
            roles: ["dosser"],
          },
        },
      ],
    },

    
    {
      title: "সেটিংস",
      icon: "HR",
      iconf: "fill-users",
      type: "sub",
      active: false,
      meta: {
        title: "Settings",
        roles: ["admin", "hr", "role"],
      },
      children: [
        {
          path: "/users",
          title: "কর্মচারীর তালিকা",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        {
          path: "/wings",
          title: "বিভাগ",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        {
          path: "/branch",
          title: "অনুবিভাগ ",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        {
          path: "/sections",
          title: "সেকশন",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        {
          path: "/designations",
          title: "পদবি",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        {
          path: "/user-role",
          title: "রোল",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr", "role"],
          },
        },
        {
          path: "/salary-scales",
          title: "বেতন স্কেল",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        {
          path: "/configurations",
          title: "কনফিগারেশনস",
          type: "link",
          active: false,
          meta: {
            title: "Settings",
            roles: ["admin", "hr"],
          },
        },
        
      ],
    },
    {
      path: "/access-log",
      title: "ইউজার লগ ",
      type: "link",
      active: false,
      icon: "Dashboard",
      iconf: "fill-board",
      meta: {
        title: "Access Log",
        roles: ["admin", "hr","access log"],
      },
    },
  ],
};
