import {
  submitAcrForm,
  getPendingAcrList,
  getUsersList,
  getEvaluationACRDetail,
  getLastThreeYearAcrMarks,
  submitDossierForm,
  getEvaluatedAcrList,
  getEvaluatedAcrDetail,
} from "@/api/acr-form";
const actions = {
  // acr form submition
  submitAcrForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      submitAcrForm(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get pending acr list
  getPendingAcrList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPendingAcrList(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get evaluated acr list
  getEvaluatedAcrList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getEvaluatedAcrList(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get pending dossier list
  getUsersList({ commit }) {
    return new Promise((resolve, reject) => {
      getUsersList()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //
  getEvaluationACRDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      getEvaluationACRDetail(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get last 3 years acr avarage marks
  getLastThreeYearAcrMarks({ commit }, data) {
    return new Promise((resolve, reject) => {
      getLastThreeYearAcrMarks(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // dossier form submition
  submitDossierForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      submitDossierForm(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get evaluated acr detail
  getEvaluatedAcrDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      getEvaluatedAcrDetail(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  // state,
  // mutations,
  actions,
};
