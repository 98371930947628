import request from "@/utils/request";

// get access log list
export function getNotificationCount() {
  return request({
    url: "/notification/get_count",
    method: "get",
  });
}

// get all notifications
export function getAllNotification() {
  return request({
    url: "/notification/user",
    method: "get",
  });
}
