// wing routes
const getWing = () => import("@/pages/hr-settings/wing");
const createWing = () =>
  import("@/pages/hr-settings/wing/create-wing.vue");
const viewWing = () =>
  import("@/pages/hr-settings/wing/update-wing.vue");

import Body from "@/components/body";
const wings = {
  path: "/wings",
  component: Body,
  meta: { title: "Wing", roles: ["hr", "admin"] },
  children: [
    // wing
    {
      path: "",
      name: "/wings-index",
      component: getWing,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/create-wing",
      name: "create-wing",
      component: createWing,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/wing-view/:id",
      name: "wing-view",
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
      component: viewWing,
    },
  ],
};

export default wings;
