const options = [
  {
    id: 1,
    name: "অত্যুত্তম",
  },
  {
    id: 2,
    name: "উত্তম",
  },
  {
    id: 3,
    name: "চলতি মান",
  },
  {
    id: 4,
    name: "চলতিমানের নিম্ন",
  },
  {
    id: 5,
    name: "নিকৃষ্ট",
  },
];
const ninethOptions = [
  {
    id: 1,
    name: "৪",
    value: 4,
  },
  {
    id: 2,
    name: "৩",
    value: 3,
  },
  {
    id: 3,
    name: "২",
    value: 2,
  },
  {
    id: 4,
    name: "১",
    value: 1,
  },
]
const tenthOptions = [
  {
    id: 1,
    name: "১০",
    value: 10,
  },
  {
    id: 2,
    name: "৮",
    value: 8,
  },
  {
    id: 3,
    name: "৬",
    value: 6,
  },
  {
    id: 4,
    name: "৪",
    value: 4,
  },
  {
    id: 5,
    name: "২",
    value: 2,
  },
]
const state = {
  questions: [
    {
      index: "১",
      id: 1,
      title: "ধীশক্তি ও মানসিক তৎপরতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "২",
      id: 2,
      title: "বিচার ক্ষমতা ও মাত্রাজ্ঞান",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৩",
      id: 3,
      title: "উদ্যোগ ও প্রচেষ্টা ",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৪",
      id: 4,
      title: "প্রকাশ ক্ষমতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৪(ক)",
      id: 5,
      title: "লিখন",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৪(খ)",
      id: 6,
      title: "বচন",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৫",
      id: 7,
      title: "কাজের পরিকল্পনা, সংগঠন ও তদারক  ক্ষমতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৬",
      id: 8,
      title: "কাজের মান ও পরিমান",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৭",
      id: 9,
      title: "অধ্যবসায় ও কর্তব্যনিষ্ঠা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৮",
      id: 10,
      title: "অধীনস্থদিকগে পরিচালনা ও প্রশিক্ষণ দানের ক্ষমতা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "৯",
      id: 11,
      title: "সহযোগিতা ও বিচক্ষণতা ",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১০",
      id: 12,
      title: "সততা",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১০(ক)",
      id: 13,
      title: "বুদ্ধিবিত্তিক",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১০(খ)",
      id: 14,
      title: "নৈতিক",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১১",
      id: 15,
      title: "দায়িত্বজ্ঞান",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১১(ক)",
      id: 16,
      title: "সাধারন",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১১(খ)",
      id: 17,
      title: "অর্থিক বিষয়ে",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১২",
      id: 18,
      title: "ব্যাক্তিত্ব",
      comment: "",
      options: options,
      required: true,
    },
    {
      index: "১৩",
      id: 19,
      title: "আদেশ পালনে তৎপরতা ",
      comment: "",
      options: options,
      required: false,
    },
    {
      index: "১৪",
      id: 20,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৫",
      id: 21,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৬",
      id: 22,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৭",
      id: 23,
      title: "",
      comment: "",
      options: options,
      status: "NTA",
      required: false,
    },
    {
      index: "১৮",
      id: 24,
      title: "সমাজকল্যাণ আগ্রহ",
      comment: "CNA",
      required: false,
      options: [
        {
          id: 1,
          name: "সমাজকল্যাণ কার্যাবলিতে আগ্রহী",
        },
        {
          id: 2,
          name: "তাহার কর্তব্যের এই দিকটিকে রুটিন মাফিক দায়িত্ব হিসাবে গণ্য করার প্রবণতা রহিয়াছে",
        },
      ],
    },
    {
        index: "১৯",
        id: 25,
        title: "অর্থনৈতিক উন্নয়নে আগ্রহ",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "উন্নয়ন প্রকল্প সমূহের প্রদিকল্পনা প্রণয়ন ও কার্জকরীকরণে আগ্রহী",
          },
          {
            id: 2,
            name: "তাঁহার কর্তব্যের এই দিকটিকে রুটিন মাফিক দায়িত্ব হিসাবে গণ্য করার প্রবণতা রহিয়াছে",
          },
        ],
      },
      {
        index: "২০",
        id: 26,
        title: "জনসাধারণের সহিত ব্যবহার",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "বিনয়ী ও সহায়ক",
          },
          {
            id: 2,
            name: "ঔদ্বত্যের প্রবণতা রহিয়াছে",
          },
        ],
      },
      {
        index: "২১",
        id: 27,
        title: "জীবনযাত্রার মান",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "আয়ের জ্ঞাত সামর্থের মধ্যে জীবিকা নির্বাহ করেন",
          },
          {
            id: 2,
            name: "আয়ের জ্ঞাত সামর্থের বাহিরে জীবিকা নির্বাহের রিপোর্ট রহিয়াছে",
          },
        ],
      },
      {
        index: "২২",
        id: 28,
        title: "নিরাপত্তা বিধি প্রতিপালন",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "যুক্তিসংগতভাবে সতর্কতামূলক ব্যবস্থা গ্রহণ করেন",
          },
          {
            id: 2,
            name: "অবহেলার প্রবণতা রহিয়াছে",
          },
        ],
      },
      {
        index: "২৩",
        id: 29,
        title: "সময়নিষ্ঠা",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "সময়নিষ্ঠ",
          },
          {
            id: 2,
            name: "সময়নিষ্ঠ নহেন",
          },
        ],
      },
      {
        index: "২৪",
        id: 30,
        title: "ভ্রমণ",
        comment: "CNA",
        required: false,
        options: [
          {
            id: 1,
            name: "পর্যাপ্ত ও রীতিসম্মত",
          },
          {
            id: 2,
            name: "অপর্যাপ্ত ও রীতি বহির্ভূত",
          },
        ],
      },
  ],
  ninethQuestions1: [
    {
      index: "৪.১ ",
      id: 1,
      title: "নৈতিকতা",
      options: ninethOptions,
    },
    {
      index: "৪.২ ",
      id: 2,
      title: "সততা",
      options: ninethOptions,
    },
    {
      index: "৪.৩ ",
      id: 3,
      title: "শৃংঙ্খলাবোধ",
      options: ninethOptions,
    },
    {
      index: "৪.৪ ",
      id: 4,
      title: "বিচার ও মাত্রাজ্ঞান",
      options: ninethOptions,
    },
    {
      index: "৪.৫ ",
      id: 5,
      title: "ব্যক্তিত্ব",
      options: ninethOptions,
    },
    {
      index: "৪.৬ ",
      id: 6,
      title: "সহযোগিতার মনোভাব",
      options: ninethOptions,
    },
    {
      index: "৪.৭ ",
      id: 7,
      title: "সময়ানুবর্তিতা",
      options: ninethOptions,
    },
    {
      index: "৪.৮ ",
      id: 8,
      title: "নির্ভরযোগ্যতা",
      options: ninethOptions,
    },
    {
      index: "৪.৯ ",
      id: 9,
      title: "দায়িত্ববোধ",
      options: ninethOptions,
    },
    {
      index: "৪.১০ ",
      id: 10,
      title: "কাজে আগ্রহ ও মনোযোগ",
      options: ninethOptions,
    },
    {
      index: "৪.১১",
      id: 11,
      title: "ঊর্ধ্বতন কর্তৃপক্ষের নির্দেশনা পালনে তৎপরতা",
      options: ninethOptions,
    },
    {
      index: "৪.১২ ",
      id: 12,
      title: "উদ্যোম ও উদ্যোগ",
      options: ninethOptions,
    },
    {
      index: "৪.১৩ ",
      id: 13,
      title: "সেবাগ্রহীতার সঙ্গে ব্যবহার",
      options: ninethOptions,
    },
    
  ],
  ninethQuestions2: [
    {
      index: "৪.১৪ ",
      id: 14,
      title: "পেশাগত জ্ঞান",
      options: ninethOptions,
    },
    {
      index: "৪.১৫ ",
      id: 15,
      title: "কাজের মান",
      options: ninethOptions,
    },
    {
      index: "৪.১৬",
      id: 16,
      title: "কর্তব্যনিষ্ঠা",
      options: ninethOptions,
    },
    {
      index: "৪.১৭ ",
      id: 17,
      title: "সম্পাদিত কাজের পরিমাণ",
      options: ninethOptions,
    },
    {
      index: "৪.১৮ ",
      id: 18,
      title: "সিদ্ধান্ত গ্রহণের দক্ষতা",
      options: ninethOptions,
    },
    {
      index: "৪.১৯",
      id: 19,
      title: "সিদ্ধান্ত বাস্তবায়নের সামর্থ্য",
      options: ninethOptions,
    },
    {
      index: "৪.২০ ",
      id: 20,
      title: "অধীনস্থদের তদারকি ও পরিচালনায় সামর্থ্য",
      options: ninethOptions,
    },
    {
      index: "৪.২১ ",
      id: 21,
      title: "দলগত কাজে সহযোগিতা ও নেতৃত্ব দানের সক্ষমতা",
      options: ninethOptions,
    },
    {
      index: "৪.২২ ",
      id: 22,
      title: "ই-নথি ও ইন্টারনেট ব্যবহারে আগ্রহ ও দক্ষতা",
      options: ninethOptions,
    },
    {
      index: "৪.২৩ ",
      id: 23,
      title: "উদ্ভাবনী কাজে আগ্রহ ও সক্ষমতা",
      options: ninethOptions,
    },
    {
      index: "৪.২৪ ",
      id: 24,
      title: " প্রকাশ ক্ষমতা (লিখন)",
      options: ninethOptions,
    },
    {
      index: "৪.২৫ ",
      id: 25,
      title: " প্রকাশ ক্ষমতা (বাচনিক)",
      options: ninethOptions,
    },
  ],
  tenthQuestions1: [
    {
      index: "০১",
      id: 1,
      title: "সততা ও সুনাম",
      options: tenthOptions,
    },
    {
      index: "০২",
      id: 2,
      title: "শৃঙ্খলাবোধ",
      options: tenthOptions,
    },
    {
      index: "০৩",
      id: 3,
      title: "সহযোগিতার মনোভাব",
      options: tenthOptions,
    },
    {
      index: "০৪",
      id: 4,
      title: "সেবাগ্রহীতার সঙ্গে ব্যবহার",
      options: tenthOptions,
    },  
  ],
  tenthQuestions2: [
    {
      index: "০৫",
      id: 5,
      title: "পেশাগত জ্ঞান ও দক্ষতা",
      options: tenthOptions,
    },
    {
      index: "০৬",
      id: 6,
      title: "কাজের মান ও পরিমাণ",
      options: tenthOptions,
    },
    {
      index: "০৭",
      id: 7,
      title: "কম্পিউটার বিষয়ক জ্ঞান ও দক্ষতা",
      options: tenthOptions,
    },
    {
      index: "০৮",
      id: 8,
      title: "ই-নথি ব্যবহারে আগ্রহ ও দক্ষতা",
      options: tenthOptions,
    },
    {
      index: "০৯",
      id: 9,
      title: "নোটিং ও ড্রাফটিং",
      options: tenthOptions,
    },
    {
      index: "১০",
      id: 10,
      title: "দাপ্তরিক কাজে উদ্যোগ ও প্রচেষ্টা",
      options: tenthOptions,
    },
  ],
  thirteenthQuestion: [
    {
      index: "০১",
      id: 1,
      title: "সততা ও সুনাম ",
      options: tenthOptions,
    },
    {
      index: "০২",
      id: 2,
      title: "শৃঙ্খলাবোধ",
      options: tenthOptions,
    },
    {
      index: "০৩",
      id: 3,
      title: "আনুগত্য ",
      options: tenthOptions,
    },
    {
      index: "০৪",
      id: 4,
      title: "সেবাগ্রহীতার সঙ্গে ব্যবহার ",
      options: tenthOptions,
    },
  ],
  thirteenthQuestion2: [
    {
      index: "০৫",
      id: 5,
      title: "পেশাগত জ্ঞান ও দক্ষতা ",
      options: tenthOptions,
    },
    {
      index: "০৬",
      id: 6,
      title: "কাজের মান ও পরিমান ",
      options: tenthOptions,
    },
    {
      index: "০৭",
      id: 7,
      title: "কম্পিউটার বিষয়ক জ্ঞান ও দক্ষতা",
      options: tenthOptions,
    },
    {
      index: "০৮",
      id: 8,
      title: "ই-নথি ব্যবহারে আগ্রহ ও দক্ষতা",
      options: tenthOptions,
    },
    {
      index: "০৯",
      id: 9,
      title: "নথি ও রেজিস্টার সংরক্ষণ ও ব্যবস্থাপনা ",
      options: tenthOptions,
    },
    {
      index: "১০",
      id: 10,
      title: "দাপ্তরিক কাজে আগ্রহ ও  দক্ষতা",
      options: tenthOptions,
    },
  ],
};

const getters = {};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
