<template>
  <div>
    <b-row class="summary-row">
      <b-col
        cols="12"
        class="mt-0"
        :md="summeryCards.length === 4 ? 3 : summeryCards.length === 3 ? 4 : 6"
        v-for="(summery, index) in summeryCards"
        :key="index"
        v-permission="summery.roles"
      >
        <b-row>
          <b-col class="pe-2" cols="12" md>
            <b-link :to="summery.link" class="link">
              <b-card class="text-center m-0">
                <div class="row me-2 p-2">
                  <div class="col-6 text-start p-2">
                    <p class="summery-title ms-1 mt-0 mb-0 p-0">
                      {{ summery.title }}
                    </p>

                    <p class="sub-text ms-1 mt-0 mb-0 p-0">
                      {{ summery.subTitle }}
                    </p>
                    
                  </div>
                  <div class="col-6 d-flex flex-row justify-content-end p-2">
                    <img
                      v-if="index == 0"
                      src="@/assets/images/ict-acr-images/dashboard/ringHeadTwo.png
                      "
                      alt="head"
                      class="ring"
                      height="25"
                      width="25"
                    />
                    <img
                      v-if="index == 1"
                      src="@/assets/images/ict-acr-images/dashboard/ringHeadOne.png
                      "
                      alt="head"
                      class="ring"
                      height="25"
                      width="25"
                    />
                    <img
                      v-if="index == 2"
                      src="@/assets/images/ict-acr-images/dashboard/ringHeadThree.png
                      "
                      alt="head"
                      class="ring"
                      height="25"
                      width="25"
                    />

                    <div class=" ">
                      <div class="circle-wrap">
                        <div class="circle">
                          <div class="mask full">
                            <div
                              class="fill"
                              :style="{ backgroundColor: summery.colorProps }"
                            ></div>
                          </div>
                          <div class="mask half">
                            <div
                              class="fill"
                              :style="{ backgroundColor: summery.colorProps }"
                            ></div>
                          </div>
                          <div
                            class="inside-circle"
                            :style="{ color: summery.colorProps }"
                          >
                            {{ summery.count.toLocaleString("bn-BN") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    summeryCards: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
/* 2nd card section */
.summery-title {
  font-size: 20px;
  font-weight: 700;
}
.sub-text {
  font-size: 14px;
  font-weight: 400;
}
.summery-sub-title {
  font-size: 32px;
}
.underline {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  color: #eeac49;
}
@media only screen and (max-width: 1500px) {
  .summery-title {
    font-size: 18px;
  }
  .summery-sub-title {
    font-size: 25px;
  }
}

/* circle */
.circle-wrap {
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
  border: 1px solid rgb(218, 213, 213);
}
.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
}

.mask .fill {
  clip: rect(0px, 60px, 60px, 0px);
  /* background-color: #227ded; */
}
.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(125deg);
}
@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(125deg);
  }
}
.circle-wrap .inside-circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: white;
  text-align: center;
  margin-top: 7px;
  margin-left: 7px;
  color: #1e51dc;
  position: absolute;
  z-index: 3;
  line-height: 60px;
  font-size: 1.75em;
  border: 1px solid rgb(218, 213, 213);
}

.summary-row .card.text-center.m-0 {
  background: linear-gradient(
    to right,
    #29b777 0%,
    26.06635071090048%,
    #278e80 42.65402843601896%,
    74.40869025999912%,
    #26458d 100%
  );
  color: #fff;
  padding: 2px;
}
</style>
