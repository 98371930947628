import { getNotificationCount, getAllNotification } from "@/api/notification";
const actions = {
  // get notification count
  getNotificationCount({ commit }) {
    return new Promise((resolve, reject) => {
      getNotificationCount()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllNotification({ commit }) {
    return new Promise((resolve, reject) => {
        getAllNotification()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,

  actions,
};
