const PendingAcr = () => import("@/pages/dossier/pending-acr/index");
const evaluatedAcr = () => import("@/pages/dossier/evaluated-acr/index");
const Report = () => import("@/pages/dossier/report/index");
const getReporter = () => import("@/pages/dossier/reporter");
const pendingAcrDetail = () => import("@/pages/dossier/pending-acr/detail");
const evaluatedAcrDetail = () => import("@/pages/dossier/evaluated-acr/detail");

import Body from "@/components/body";
const dossierRoute = {
  path: "/dossier",
  component: Body,
  meta: {
    title: "Dossier",
    roles: ["dosser"],
  },
  children: [
    {
      path: "reporters",
      name: "reporters-index",
      component: getReporter,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "report",
      name: "report-index",
      component: Report,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "pending-acr",
      name: "dossier-pending-acr",
      component: PendingAcr,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "pending-acr-detail/:id/:year/:form",
      name: "dossier-pending-acr-detail",
      component: pendingAcrDetail,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "evaluated-acr",
      name: "dossier-evaluated-acr",
      component: evaluatedAcr,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "evaluated-acr-detail/:id",
      name: "dossier-evaluated-acr-detail",
      component: evaluatedAcrDetail,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
  ],
};

export default dossierRoute;
