<template>
  <div class="col-xxl-4 col-sm-6 box-col-6">
    <div class="card profile-box">
      <div class="card-body">
        <div class="media">
          <div class="media-body">
            <div class="greeting-user">
              <h4 class="f-w-600 mb-0">Welcome to cuba</h4>
              <p>Here whats happing in your account today</p>
              <div class="whatsnew-btn">
                <a class="btn btn-outline-white">Whats New !</a>
              </div>
            </div>
          </div>
        </div>
        <div class="cartoon">
          <img
            class="img-fluid"
            src="@/assets/images/dashboard/cartoon.svg"
            alt="vector women with leptop"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socialDashboard } from "../../../data/comon";
export default {
  data() {
    return {
      socialDashboard: socialDashboard,
      interval: "",
      greeting: "",
      time: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-box {
  background: linear-gradient(
    to right,
    #29b777 0%,
    26.06635071090048%,
    #278e80 42.65402843601896%,
    74.40869025999912%,
    #26458d 100%
  );

  /* Gradient in Hex */

  /* Gradient in RGBA */
  // linear-gradient(to right, rgba(41, 183, 119, 1) 0%, 24.644549763033176%, rgba(39, 142, 128, 1) 49.28909952606635%, 74.64454976303318%, rgba(38, 69, 141, 1) 100%);
  /* Gradient in Hex */
  /* Gradient in RGBA */
  // linear-gradient(to right, rgba(41, 183, 119, 1) 0%, 26.06635071090048%, rgba(39, 142, 128, 1) 42.65402843601896%, 74.40869025999912%, rgba(38, 69, 141, 1) 100%);
}
</style>
