const Token = "Admin-Token";
const AcrData = "AcrData";
const medical_file = "medical_file";
const Questions = "Questions";
const IroData = "IroData";
const CroData = "CroData";
const DossierData = "DossierData";
const TenUser = "TenUser";
const TenthQuestions = "TenthQuestions";
const TenthIroComment = "TenthIroComment";
const TenthCroData = "TenthCroData";
const report_type = "report_type";
const ninth_report_type = "ninth_report_type";
const ThirteenUser = "ThirteenUser";
const ThirteenthQuestions = "ThirteenthQuestions";
const ThirteenthIroComment = "ThirteenthIroComment";
const ThirteenthCroData = "ThirteenthCroData";
const ThirteenthReportType = "ThirteenthReportType";

export function getToken() {
  return localStorage.getItem(Token);
}

export function setToken(token) {
  return localStorage.setItem(Token, token);
}

export function removeToken() {
  return localStorage.removeItem(Token);
}

export function getLogedInUser() {
  return localStorage.getItem("User");
}

export function removeLogedInUser() {
  return localStorage.removeItem("User");
}

export function setLogedInUserInfo(userInfo) {
  return localStorage.setItem("UserInfo", userInfo);
}
export function getLogedInUserInfo() {
  return localStorage.getItem("UserInfo");
}

export function removeLogedInUserInfo() {
  return localStorage.removeItem("UserInfo");
}

export function setNotification(Notification) {
  return localStorage.setItem("Notification", Notification);
}
export function getNotification() {
  return localStorage.getItem("Notification");
}

// role set
export function setUserRole(userROle) {
  return localStorage.setItem("userROle", userROle);
}
export function getUserRole() {
  return localStorage.getItem("userROle");
}

export function setAcrInfo(data) {
  return localStorage.setItem(AcrData, data);
}

export function getAcrInfo() {
  return localStorage.getItem(AcrData);
}

export function removeAcrInfo() {
  return localStorage.removeItem(AcrData);
}

export function setMedicalFile(data) {
  return localStorage.setItem(medical_file, data);
}

export function getMedicalFile() {
  return localStorage.getItem(medical_file);
}

export function removeMedicalFile() {
  return localStorage.removeItem(medical_file);
}

export function setQuestions(data) {
  return localStorage.setItem(Questions, data);
}

export function getQuestions() {
  return localStorage.getItem(Questions);
}

export function removeQuestions() {
  return localStorage.removeItem(Questions);
}

export function setIroData(data) {
  return localStorage.setItem(IroData, data);
}

export function getIroData() {
  return localStorage.getItem(IroData);
}

export function removeIroData() {
  return localStorage.removeItem(IroData);
}

export function setCroData(data) {
  return localStorage.setItem(CroData, data);
}

export function getCroData() {
  return localStorage.getItem(CroData);
}

export function removeCroData() {
  return localStorage.removeItem(CroData);
}

export function setDossierData(data) {
  return localStorage.setItem(DossierData, data);
}

export function getDossierData() {
  return localStorage.getItem(DossierData);
}

export function removeDossierData() {
  return localStorage.removeItem(DossierData);
}

export function setNinthReportType(data) {
  return localStorage.setItem(ninth_report_type, data);
}

export function getNinthReportType() {
  return localStorage.getItem(ninth_report_type);
}

export function removeNinthReportType() {
  return localStorage.removeItem(ninth_report_type);
}

// tenth to twelve data

export function setTenUserData(data) {
  return localStorage.setItem(TenUser, data);
}

export function getTenUserData() {
  return localStorage.getItem(TenUser);
}

export function removeTenUserData() {
  return localStorage.removeItem(TenUser);
}

export function setTenthQuestions(data) {
  return localStorage.setItem(TenthQuestions, data);
}

export function getTenthQuestions() {
  return localStorage.getItem(TenthQuestions);
}

export function removeTenthQuestions() {
  return localStorage.removeItem(TenthQuestions);
}

export function setTenthIroComment(data) {
  return localStorage.setItem(TenthIroComment, data);
}

export function getTenthIroComment() {
  return localStorage.getItem(TenthIroComment);
}

export function removeTenthIroComment() {
  return localStorage.removeItem(TenthIroComment);
}

export function setTenthCroData(data) {
  return localStorage.setItem(TenthCroData, data);
}

export function getTenthCroData() {
  return localStorage.getItem(TenthCroData);
}

export function removeTenthCroData() {
  return localStorage.removeItem(TenthCroData);
}

export function setReportType(data) {
  return localStorage.setItem(report_type, data);
}

export function getReportType() {
  return localStorage.getItem(report_type);
}

export function removeReportType() {
  return localStorage.removeItem(report_type);
}

// thirteen to sixteen data

export function setThirteenUserData(data) {
  return localStorage.setItem(ThirteenUser, data);
}

export function getThirteenUserData() {
  return localStorage.getItem(ThirteenUser);
}

export function removeThirteenUserData() {
  return localStorage.removeItem(ThirteenUser);
}

export function setThirteenthQuestions(data) {
  return localStorage.setItem(ThirteenthQuestions, data);
}

export function getThirteenthQuestions() {
  return localStorage.getItem(ThirteenthQuestions);
}

export function removeThirteenthQuestions() {
  return localStorage.removeItem(ThirteenthQuestions);
}

export function setThirteenthIroComment(data) {
  return localStorage.setItem(ThirteenthIroComment, data);
}

export function getThirteenthIroComment() {
  return localStorage.getItem(ThirteenthIroComment);
}

export function removeThirteenthIroComment() {
  return localStorage.removeItem(ThirteenthIroComment);
}

export function setThirteenthCroData(data) {
  return localStorage.setItem(ThirteenthCroData, data);
}

export function getThirteenthCroData() {
  return localStorage.getItem(ThirteenthCroData);
}

export function removeThirteenthCroData() {
  return localStorage.removeItem(ThirteenthCroData);
}

export function setThirteenthReportType(data) {
  return localStorage.setItem(ThirteenthReportType, data);
}

export function getThirteenthReportType() {
  return localStorage.getItem(ThirteenthReportType);
}

export function removeThirteenthReportType() {
  return localStorage.removeItem(ThirteenthReportType);
}
