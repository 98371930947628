<template>
  <div id="dashboard-table">
    <b-row>
      <b-col>
        <b-table
          :items="list"
          :fields="fields"
          head-variant="dark"
          striped
        >
          <template #cell(name)="row">
            <p>
              {{ row.item.User.banglaName }}
            </p>
          </template>
          <template #cell(userIdNo)="row">
            <p>
              {{ row.item.user_id }}
            </p>
          </template>
          <template #cell(designation)="row">
            <p>
              {{ row.item.designation }}
            </p>
          </template>
          <template #cell(deadline)="row">
            <p>
              {{ getHumanDateWithoutTime(row.item.start_date) }}
              -
              {{ getHumanDateWithoutTime(row.item.end_date) }}
            </p>
          </template>
          <template #cell(createdAt)="row">
            <p>
              {{ getHumanDate(row.item.createdAt) }}
            </p>
          </template>
          <template #cell(action)="row">
            <div v-if="type !== 'user'">
              <div class="row">
                <div class="col-12">
                  <b-link
                    v-if="checkPermission(['iro']) && row.item.status === 'iro'"
                    :to="`/ten-to-twelve-iro-evaluation/${row.item.id}`"
                    class="btn btn-sm bg-white btn-section shadow-lg text-black"
                  >
                    অনুস্বাক্ষর
                  </b-link>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b-link
                    v-if="checkPermission(['cro']) && row.item.status === 'cro'"
                    :to="`/ten-to-twelve-cro-evaluation/${row.item.id}`"
                    class="btn btn-sm bg-white btn-section shadow-lg text-black"
                  >
                    প্রতিস্বাক্ষর
                  </b-link>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div v-if="checkPermission(['dosser']) && row.item.status === 'dosser'">
                    <b-link
                      :to="`/ten-to-twelve-dossier-evaluation/${row.item.id}`"
                      class="btn btn-sm bg-white shadow-lg btn-section text-black ms-1"
                    >
                      সংরক্ষণ করুন
                    </b-link>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div v-if="checkPermission(['dosser', 'admin']) && row.item.status === 'done'">
                    <b-link
                      :to="`/ninth-form-acr-print/${row.item.id}`"
                      class="btn btn-sm bg-white shadow shadow-lg ms-1"
                    >
                      <i class="fa fa-print text-black" aria-hidden="true"></i>
                    </b-link>
                    <b-link
                      :to="`/ninth-form-acr-preview/${row.item.id}`"
                      class="btn btn-sm bg-white shadow-lg btn-section text-black ms-1"
                    >
                      বিস্তারিত
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <p
                  v-if="row.item.status === 'iro'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  অনুস্বাক্ষর ({{ row.item.iro }})
                </p>
                <p
                  v-if="row.item.status === 'cro'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  প্রতিস্বাক্ষর ({{ row.item.iro }})
                </p>
                <p
                  v-if="row.item.status === 'done'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  ডোসিয়ার এর কাছে সংরক্ষিত
                </p>
                <p
                  v-if="row.item.status === 'dosser'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  ডোসিয়ার এর কাছে অপেক্ষমান
                </p>
                <p
                  v-if="row.item.status === 'user'"
                  class="btn btn-sm bg-white shadow-lg text-black"
                >
                  ফেরত পাঠানো হয়েছে
                </p>
              </div>
            </div>
          </template>
        </b-table>
        <!-- <div class="text-center">
          <h5 v-if="list.length == 0" class="text-muted mt-1">
            আপনার কোন অনুস্বাক্ষর আবেদন নেই !
          </h5>
        </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getUserRole, getLogedInUser } from "@/utils/auth";
import checkPermission from "@/utils/permission";
import moment from "moment";
export default {
  props: {
    list: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loggedInUserRole: JSON.parse(getUserRole()),
      loggedInUser: JSON.parse(getLogedInUser()),
      fields: [
        {
          key: "name",
          label: "অনুবেদকারী কর্মচারী",
          sortable: true,
        },
        {
          key: "userIdNo",
          label: "আইডি",
          sortable: true,
        },
        {
          key: "designation",
          label: "পদবি",
        },
        {
          key: "deadline",
          label: "অনুবেদনের সময়সীমা ",
        },
        {
          key: "createdAt",
          label: "প্রাপ্তির তারিখ",
        },
        {
          key: "action",
          label: this.type !== "user" ? "একশন" : "স্ট্যাটাস",
        },
      ],
    };
  },
  methods: {
    checkPermission,
    getHumanDate: function (date) {
      // return moment(date).locale("bn-BD").format("YYYY-MM-DD HH:mm:ss");
      return moment(date).locale("en-US").format("YYYY-MM-DD hh:mm A");
    },
    getHumanDateWithoutTime: function (date) {
      return moment(date, "YYYY-MM-DD").locale("en-US").format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.btn-section {
  width: 90px !important;
}

th {
  width: 17% !important;
}
</style>
