import request from "../../utils/request";
export function getReporter() {
  return request({
    url: "/reporter",
    method: "GET",
  });
}
export function getReporterWithPagination(query) {
  return request({
    url: "/reporter/pagination",
    method: "GET",
    params: query,
  });
}
export function bulkCreateReporter(data) {
  return request({
    url: "/area/reporter_assign",
    method: "POST",
    data,
  });
}

export function editReporter(data) {
  return request({
    url: "/area/reporter_edit",
    method: "patch",
    data,
  });
}

export function createReporter(data) {
  return request({
    url: "/reporter",
    method: "POST",
    data: {
      iro: data.iro,
      user_id: data.user_id,
      gread: data.gread,
      designation: data.designation,
      cro: data.cro,
      joining_date_current_position: data.joining_date_current_position,
      start_date: data.start_date,
      end_date: data.end_date,
    },
  });
}

export function updateReporter(data) {
  console.log("check data  from api", data);
  return request({
    url: "/reporter/" + data.id,
    method: "put",
    data: {
      iro: data.iro,
      user_id: data.user_id,
      gread: data.gread,
      designation: data.designation,
      cro: data.cro,
      joining_date_current_position: data.joining_date_current_position,
      start_date: data.start_date,
      end_date: data.end_date,
      submited: data.submited,
      status: data.status,
      route: data.route,
      comment: data.comment,
    },
  });
}

export function getReporterById(id) {
  return request({
    url: "/reporter/user/" + id,
    method: "get",
  });
}
export function getReportInfo(id) {
  return request({
    url: "/reporter/" + id,
    method: "get",
  });
}
export function deleteReporterById(id) {
  return request({
    url: "/reporter/" + id,
    method: "delete",
  });
}

export function croToIro(data) {
  return request({
    url: "/reporter/cro-iro",
    method: "patch",
    data,
  });
}

export function getAllRootUsers() {
  return request({
    url: "/root/user",
    method: "get",
  });
}

export function getAllData() {
  return request({
    url: "/reporter/report/helper",
    method: "get",
  });
}

export function getReport(query) {
  return request({
    url: "/reporter/report",
    method: "get",
    params: query,
  });
}

export function getReporterFormById(id) {
  return request({
    url: "/reporter/" + id,
    method: "get",
  });
}

export function getAllReporter(data) {
  return request({
    url: "/reporter/dashboard/report/",
    method: "get",
    params: data,
  });
}
