<template>
  <div>
    <ul class="folder">
      <li>
        <tree-content-component
          :tree="folder"
          @getOpenClose="getOpenClose"
        ></tree-content-component>
        <transition name="fade">
          <tree-folder-contents
            :children="folder.children"
            v-if="open"
          ></tree-folder-contents>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import TreeFolderContents from "./tree-folder-contents.vue";
import TreeContentComponent from "./tree-content-component.vue";
export default {
  components: {
    TreeFolderContents,
    TreeContentComponent,
  },
  data() {
    return {
      open: false,
    };
  },
  props: {
    folder: {
      type: Object,
    },
  },
  methods: {
    getOpenClose(value) {
      this.open = value
    },
  },
};
</script>

<style scoped>
/* .open, .close, .contents li {
  margin: 20px 0px;
} */
</style>
