import {
  getSalaryScale,
  createSalaryScale,
  updateSalaryScale,
  getSalaryScaleById,
} from "@/api/hr-settings/salary-scale";
const actions = {
  getSalaryScale({ commit }) {
    return new Promise((resolve, reject) => {
      getSalaryScale()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createSalaryScale({ commit }, data) {
    return new Promise((resolve, reject) => {
      createSalaryScale(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSalaryScale({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateSalaryScale(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSalaryScaleById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getSalaryScaleById(id)
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
