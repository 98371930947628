<template>
  <div id="default-dashboard">
    <Breadcrumbs :title="$route.name" class="mt-3" />
    <div
      v-if="
        checkPermission(['admin', 'iro', 'cro', 'dosser', 'hr', 'applicant'])
      "
    >
      <b-row>
        <b-col cols="12">
          <form-card />
        </b-col>
        <b-col cols="12">
          <info-card :summeryCards="employeeItems" />
        </b-col>
        <b-col cols="12" class="mt-4">
          <info-card :summeryCards="pendingItems" />
        </b-col>
      </b-row>
      <div v-if="checkPermission(['iro', 'cro', 'applicant'])" class="mt-4">
        <b-tabs content-class="mt-3">
          <b-tab v-if="loggedInUser.grade <= 9 || (loggedInUser.grade <= 16 && loggedInUser.grade >= 13)" class="custom-tab-1">
            <template #title>
              <strong>গ্রেড ১৩তম থেকে গ্রেড ১৬তম</strong>
              <b-badge
                class="ms-2"
                v-if="pendingCountSixteen > 0"
                variant="danger"
                >{{ pendingCountSixteen }}</b-badge
              >
            </template>
            <thirteen-to-sixteen-form-acr-list
              @showCountSixteen="showCountSixteen"
            />
          </b-tab>
          <b-tab v-if="loggedInUser.grade <= 12">
            <template #title>
              <strong>গ্রেড ১০ম থেকে গ্রেড ১২তম</strong>
              <b-badge
                class="ms-2"
                v-if="pendingCountTen > 0"
                variant="danger"
                >{{ pendingCountTen }}</b-badge
              >
            </template>
            <ten-to-twelve-form-acr-list @showCountTen="showCountTen" />
          </b-tab>
          <b-tab active v-if="loggedInUser.grade <= 9">
            <template #title>
              <strong>গ্রেড ৯ম ও তদূর্ধ্ব গ্রেডের রিপোর্ট</strong>
              <b-badge
                class="ms-2"
                v-if="pendingCountNine > 0"
                variant="danger"
                >{{ pendingCountNine }}</b-badge
              >
            </template>
            <ninth-form-acr-list @showCountNine="showCountNine" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <h3
      v-else-if="
        checkPermission(['role', 'access log', 'reporter assign', 'user'])
      "
      class="m-5"
    >
      Hello, {{ loggedInUser.banglaName }}. Welcome to your dashboard.
    </h3>
  </div>
</template>

<script>
import formCard from "./default/formCard.vue";
import infoCard from "./default/infoCard.vue";
import ninthFormAcrList from "@/pages/acr/ninth-form/list.vue";
import tenToTwelveFormAcrList from "@/pages/acr/ten-to-twelve/list.vue";
import thirteenToSixteenFormAcrList from "@/pages/acr/thirteen-to-sixteen/list.vue";
import { getLogedInUserInfo } from "@/utils/auth";
import WelcomeCard from "./default/WelcomeCard.vue";
import checkPermission from "@/utils/permission";

export default {
  name: "DefaultDashboard",
  components: {
    formCard,
    infoCard,
    ninthFormAcrList,
    tenToTwelveFormAcrList,
    thirteenToSixteenFormAcrList,
    WelcomeCard,
  },
  data() {
    return {
      loggedInUser: JSON.parse(getLogedInUserInfo()),
      pendingCountNine: 0,
      pendingCountTen: 0,
      pendingCount: 0,
      pendingCountSixteen: 0,
      pendingCountSixteen: 0,
      employeeItems: [
        {
          title: "মোট  কর্মচারী ",
          subTitle: "",
          count: 0,
          roles: ["admin", "dosser", "hr"],
          colorProps: "#54b474",
        },
        {
          title: "সক্রিয়  কর্মচারী ",
          subTitle: "",
          count: 0,
          roles: ["admin", "dosser", "hr"],
          colorProps: "#54b474",
        },
        {
          title: "নিক্রিয়  কর্মচারী ",
          subTitle: "",
          count: 0,
          roles: ["admin", "dosser", "hr"],
          colorProps: "#54b474",
        },
      ],
      pendingItems: [
        {
          title: "মূল্যায়ন বাকি",
          subTitle: "(অনুবেদনাধীর)",
          count: 0,
          roles: ["admin", "applicant", "dosser"],
          colorProps: "#54b474",
        },
        {
          title: "মূল্যায়ন বাকি",
          subTitle: "(অনুবেদনকারী)",
          count: 0,
          roles: ["admin", "iro", "dosser"],
          colorProps: "#54b474",
        },
        {
          title: "মূল্যায়ন বাকি",
          subTitle: "(প্রতিস্বাক্ষরকারী)",
          count: 0,
          roles: ["admin", "cro", "dosser"],
          colorProps: "#54b474",
        },
        {
          title: "মূল্যায়ন বাকি",
          subTitle: "(ডোসিয়ার)",
          count: 100,
          roles: ["admin", "dosser"],
          colorProps: "#54b474",
        },
      ],
    };
  },
  mounted() {
    if (this.checkPermission(["admin", "dosser", "iro", "cro", "hr"])) {
      this.getDashboardInfo();
    }
  },
  methods: {
    checkPermission,
    showCountNine(count) {
      this.pendingCountNine = count;
    },
    showCountTen(count) {
      this.pendingCountTen = count;
    },
    showCountSixteen(count) {
      this.pendingCountSixteen = count;
    },
    getDashboardInfo(){
      this.$store.dispatch("auth/getDashboardInfo").then((res) => {
        if (res.status === 200) {
          const report = res.data.report;
          this.employeeItems[0].count = report.user_total;
          this.employeeItems[1].count = report.user_active;
          this.employeeItems[2].count = report.user_inactive;
          this.pendingItems[0].count = report.user_processing;
          this.pendingItems[1].count = report.pending_iro_evaluation;
          this.pendingItems[2].count = report.pending_cro_evaluation;
          this.pendingItems[3].count = report.pending_dosser_evaluation;
        }
      });
    }
  },
};
</script>
