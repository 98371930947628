import {
  getReporter,
  getReporterById,
  deleteReporterById,
  createReporter,
  updateReporter,
  getReporterWithPagination,
  getReportInfo,
  bulkCreateReporter,
  editReporter,
  croToIro,
  getAllRootUsers,
  getAllData,
  getReport,
  getReporterFormById,
  getAllReporter
} from "../../../api/hr-settings/reporter";
const actions = {
  getReporter({ commit }) {
    return new Promise((resolve, reject) => {
      getReporter()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createReporter({ commit }, data) {
    return new Promise((resolve, reject) => {
      createReporter(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  bulkCreateReporter({ commit }, data) {
    return new Promise((resolve, reject) => {
      bulkCreateReporter(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateReporter({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateReporter(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReporterById({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      getReporterById(user_id)
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReportInfo({ commit }, user_id) {
    return new Promise((resolve, reject) => {
      getReportInfo(user_id)
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReporterWithPagination({ commit }, id) {
    return new Promise((resolve, reject) => {
      getReporterWithPagination(id)
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteReporterById({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteReporterById(id)
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editReporter({ commit }, data) {
    return new Promise((resolve, reject) => {
      editReporter(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  croToIro({ commit }, data) {
    console.log("item from store", data);
    return new Promise((resolve, reject) => {
      croToIro(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAllRootUsers({ commit }) {
    return new Promise((resolve, reject) => {
      getAllRootUsers()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get all helper data for report section
  getAllData({ commit }) {
    return new Promise((resolve, reject) => {
      getAllData()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // getReport
  getReport({ commit }, query) {
    return new Promise((resolve, reject) => {
      getReport(query)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // getReporterFormById
  getReporterFormById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getReporterFormById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllReporter({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAllReporter(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  actions,
};
