import { getRoot } from "@/api/app-config";
export default {
  namespaced: true,
  state: {
    imageBaseUrl: "https://api-acr-bcc.inflack.xyz",
    tempFileUrl: "https://api-acr-bcc.inflack.xyz/temp-document/",
    mainFileUrl: "https://api-acr-bcc.inflack.xyz/document/",
    modal: {},
  },
  mutations: {
    SET_MODAL_VALUE: (state, modal) => {
      state.modal = modal;
    },
  },

  actions: {
    showModal({ commit }, payload) {
      commit("SET_MODAL_VALUE", payload);
    },
    getRoot({ commit }) {
      return new Promise((resolve, reject) => {
        getRoot()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
};
