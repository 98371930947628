<template>
  <div>
    <b-row class="mx-1">
      <b-col>
        <h5 class="m-0 p-0 filter-title mb-2">
          অনুরোধ তালিকা (ডোসিয়ার এর কাছে অপেক্ষমান || গ্রেড ১০ম থেকে গ্রেড ১২তম গ্রেডের অফিসার)
        </h5>
        <table-component type="dossier" :list="list" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import tableComponent from "./table.vue";
export default {
  components: {
    tableComponent,
  },
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style></style>
