const state = {
  grades: [
    { id: "1", grade: "Grade - 1" },
    { id: "2", grade: "Grade - 2" },
    { id: "3", grade: "Grade - 3" },
    { id: "4", grade: "Grade - 4" },
    { id: "5", grade: "Grade - 5" },
    { id: "6", grade: "Grade - 6" },
    { id: "7", grade: "Grade - 7" },
    { id: "8", grade: "Grade - 8" },
    { id: "9", grade: "Grade - 9" },
    { id: "10", grade: "Grade - 10" },
    { id: "11", grade: "Grade - 11" },
    { id: "12", grade: "Grade - 12" },
    { id: "13", grade: "Grade - 13" },
    { id: "14", grade: "Grade - 14" },
    { id: "15", grade: "Grade - 15" },
    { id: "16", grade: "Grade - 16" },
    { id: "17", grade: "Grade - 17" },
    { id: "18", grade: "Grade - 18" },
    { id: "19", grade: "Grade - 19" },
    { id: "20", grade: "Grade - 20" },
  ],
};

const getters = {};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
