import request from "@/utils/request";
export function getSalaryScale() {
  return request({
    url: "/salary-range",
    method: "GET",
  });
}
export function createSalaryScale(data) {
  return request({
    url: "/salary-range/create",
    method: "POST",
    data: data,
  });
}

export function updateSalaryScale(data) {
  return request({
    url: "/salary-range/update",
    method: "patch",
    data,
  });
}

export function getSalaryScaleById(id) {
  return request({
    url: "/salary-range/detail/",
    method: "get",
    params: {
      grade: id,
    },
  });
}
