// salary-scale routes
const getSalaryScale = () => import("@/pages/hr-settings/salary-scale");
const createSalaryScale = () =>
  import("@/pages/hr-settings/salary-scale/create-salary-scale.vue");
const viewSalaryScale = () =>
  import("@/pages/hr-settings/salary-scale/update-salary-scale.vue");

import Body from "@/components/body";
const salaryScale = {
  path: "/salary-scales",
  component: Body,
  meta: { title: "Salary Scale", roles: ["hr", "admin"] },
  children: [
    // salary-scale
    {
      path: "",
      name: "/salary-scales-index",
      component: getSalaryScale,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/create-salary-scale",
      name: "create-salary-scale",
      component: createSalaryScale,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/salary-scale-view/:id",
      name: "salary-scale-view",
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
      component: viewSalaryScale,
    },
  ],
};

export default salaryScale;
