<template>
  <div class="border rounded bg-white py-3">
    <iro-table v-if="checkPermission(['iro'])" :list="iroList" />
    <cro-table v-if="checkPermission(['cro'])" :list="croList" />
    <!-- <dossier-table v-if="checkPermission(['admin', 'dosser'])" :list="dossierList" />
    <done-table v-if="checkPermission(['admin', 'dosser', 'user'])" :list="doneList" /> -->
    <user-table v-if="checkPermission(['applicant'])" :list="userList" />
    <!-- <user-done-table v-if="checkPermission(['user', 'applicant'])" :list="userDoneList" /> -->
  </div>
</template>

<script>
import iroTable from "./components/iro-table.vue";
import croTable from "./components/cro-table.vue";
import dossierTable from "./components/dossier-table.vue";
import userTable from "./components/user-table.vue";
import userDoneTable from "./components/user-done-table.vue";
import doneTable from "./components/done-table.vue";
import checkPermission from "@/utils/permission";

export default {
  components: {
    iroTable,
    croTable,
    dossierTable,
    userTable,
    doneTable,
    userDoneTable,
  },
  data() {
    return {
      list: [],
      iroList: [],
      croList: [],
      dossierList: [],
      userList: [],
      doneList: [],
      userDoneList: [],
    };
  },
  mounted() {
    this.viewAllGazzetedCadreAcr();
  },
  methods: {
    checkPermission,
    viewAllGazzetedCadreAcr() {
      const query = {
        from: 1,
        to: 9,
      };
      this.$store
        .dispatch("reporter/getAllReporter", query)
        .then((response) => {
          this.list = response.data;
          this.iroList = this.list.iro_list;
          this.croList = this.list.cro_list;
          this.dossierList = this.list.dosser_list;
          this.doneList = this.list.total_done_list;
          this.userList = this.list.user_ongoing_list;
          this.userDoneList = this.list.user_done_list;
          this.$emit("showCountNine", this.list.pending_count)
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
