// acr gazetted cadre routes
const acrListGazzetedCadre = () => import("@/pages/acr/ninth-form/index.vue");
const acrFormCadreGazzeted = () => import("@/pages/acr/ninth-form/form.vue");
const acrMedicalCadreGazzeted = () =>
  import("@/pages/acr/ninth-form/medical.vue");

const formPartTwo = () => import("@/pages/acr/ninth-form/form-part-two.vue");
const formPartThree = () =>
  import("@/pages/acr/ninth-form/form-part-three.vue");

const ninthFormAcrEvaluation = () =>
  import("@/pages/acr/ninth-form/evaluation.vue");

const ninthFormAcrIroAssessment = () =>
  import("@/pages/acr/ninth-form/iro-assessment.vue");
const ninthFormAcrIroAssessmentPreview = () =>
  import("@/pages/acr/ninth-form/iro-assessment-preview.vue");

const formPartFive = () => import("@/pages/acr/ninth-form/form-part-five.vue");
const iroEvaluationPreview = () =>
  import("@/pages/acr/ninth-form/iro-evaluation-preview.vue");
const ninthFormCroEvaluation = () =>
  import("@/pages/acr/ninth-form/cro-evaluation.vue");
const ninthFormCroEvaluationPreview = () =>
  import("@/pages/acr/ninth-form/cro-evaluation-preview.vue");
const formPartSix = () => import("@/pages/acr/ninth-form/form-part-six.vue");
const ninthFormDossierPreview = () =>
  import("@/pages/acr/ninth-form/dossier-preview.vue");
const ninthFormDossierEvaluation = () =>
  import("@/pages/acr/ninth-form/dossier-evaluation.vue");

const ninthFormDossierEvaluationPreview = () =>
  import("@/pages/acr/ninth-form/dossier-evaluation-preview.vue");
const ninthFormAcrPreview = () => import("@/pages/acr/ninth-form/view.vue");

// 10 to 12 form routes
const tenToTwelveGradeForm = () => import("@/pages/acr/ten-to-twelve/form.vue");
const tenToTwelveUserPart = () =>
  import("@/pages/acr/ten-to-twelve/user-part.vue");
const tenToTwelveUserPartPreview = () =>
  import("@/pages/acr/ten-to-twelve/user-part-preview.vue");
const tenToTwelveIroEvaluation = () =>
  import("@/pages/acr/ten-to-twelve/evaluation.vue");
const tenToTwelveIroAssessment = () =>
  import("@/pages/acr/ten-to-twelve/iro-assessment.vue");
const tenToTwelveIroAssessmentPreview = () =>
  import("@/pages/acr/ten-to-twelve/iro-assessment-preview.vue");
const tenToTwelveCroEvaluation = () =>
  import("@/pages/acr/ten-to-twelve/cro-evaluation.vue");
const tenToTwelveCroEvaluationForm = () =>
  import("@/pages/acr/ten-to-twelve/cro-evaluation-form.vue");
const tenToTwelveCroEvaluationPreview = () =>
  import("@/pages/acr/ten-to-twelve/cro-evaluation-preview.vue");
const tenToTwelveDossierEvaluation = () =>
  import("@/pages/acr/ten-to-twelve/dossier-evaluation.vue");
const tenToTwelveFormAcrPreview = () =>
  import("@/pages/acr/ten-to-twelve/view.vue");

// 13 to 16 form routes
const thirteenToSixteenGradeForm = () =>
  import("@/pages/acr/thirteen-to-sixteen/form.vue");
const thirteenToSixteenUserPartPreview = () =>
  import("@/pages/acr/thirteen-to-sixteen/user-part-preview.vue");
const thirteenToSixteenUserPart = () =>
  import("@/pages/acr/thirteen-to-sixteen/user-part.vue");
const thirteenToSixteenIroEvaluation = () =>
  import("@/pages/acr/thirteen-to-sixteen/evaluation.vue");
const thirteenToSixteenIroAssessment = () =>
  import("@/pages/acr/thirteen-to-sixteen/iro-assessment.vue");
const thirteenToSixteenIroAssessmentPreview = () =>
  import("@/pages/acr/thirteen-to-sixteen/iro-assessment-preview.vue");
const thirteenToSixteenCroEvaluation = () =>
  import("@/pages/acr/thirteen-to-sixteen/cro-evaluation.vue");
const thirteenToSixteenCroEvaluationForm = () =>
  import("@/pages/acr/thirteen-to-sixteen/cro-evaluation-form.vue");
const thirteenToSixteenCroEvaluationPreview = () =>
  import("@/pages/acr/thirteen-to-sixteen/cro-evaluation-preview.vue");
const thirteenToSixteenDossierEvaluation = () =>
  import("@/pages/acr/thirteen-to-sixteen/dossier-evaluation.vue");
const thirteenToSixteenFormAcrPreview = () =>
  import("@/pages/acr/thirteen-to-sixteen/view.vue");

  const DossierEvaluation = () =>
  import("@/pages/acr/dossier/dossier-evaluation.vue");

const DossierEvaluationPreview = () =>
  import("@/pages/acr/dossier/dossier-evaluation-preview.vue"); 

import Body from "@/components/body";
const ACRRoute = {
  path: "/acr",
  component: Body,
  children: [
    // acr ninth-form routes
    {
      path: "/acr-list-ninth-form",
      name: "gazzeted-acr-list-cadre",
      component: acrListGazzetedCadre,
      meta: { requiredAuth: true },
    },

    {
      path: "/ninth-form/:id",
      name: "ninth-form",
      component: acrFormCadreGazzeted,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },

    {
      path: "/ninth-form-medical/:id",
      name: "ninth-form-medical",
      component: acrMedicalCadreGazzeted,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },

    {
      path: "/ninth-form-part-two/:id",
      name: "ninth-form-part-two",
      component: formPartTwo,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/ninth-form-part-three/:id",
      name: "ninth-form-part-three",
      component: formPartThree,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/ninth-form-acr-evaluation/:id",
      name: "ninth-form-acr-evaluation",
      component: ninthFormAcrEvaluation,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ninth-form-acr-iro-assessment/:id",
      name: "ninth-form-iro-assessment",
      component: ninthFormAcrIroAssessment,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ninth-form-acr-iro-assessment-preview/:id",
      name: "ninth-form-iro-assessment-preview",
      component: ninthFormAcrIroAssessmentPreview,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ninth-form-part-five/:id",
      name: "ninth-form-part-five",
      component: formPartFive,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/iro-evaluation-preview/:id",
      name: "iro-evaluation-preview",
      component: iroEvaluationPreview,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ninth-form-cro-evaluation/:id",
      name: "ninth-form-cro-evaluation",
      component: ninthFormCroEvaluation,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/ninth-form-cro-evaluation-preview/:id",
      name: "ninth-form-cro-evaluation-preview",
      component: ninthFormCroEvaluationPreview,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/form-part-six/:id",
      name: "form-part-six",
      component: formPartSix,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/ninth-form-dossier-preview/:id",
      name: "ninth-form-dossier-preview",
      component: ninthFormDossierPreview,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "/ninth-form-dossier-evaluation/:id",
      name: "ninth-form-dossier-evaluation",
      component: ninthFormDossierEvaluation,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "/ninth-form-dossier-evaluation-preview/:id",
      name: "ninth-form-dossier-evaluation-preview",
      component: ninthFormDossierEvaluationPreview,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "/ninth-form-acr-preview/:id",
      name: "ninth-form-acr-preview",
      component: ninthFormAcrPreview,
      meta: { requiredAuth: true, roles: ["dosser", "admin"] },
    },

    // 10 to 12 form routes
    {
      path: "/ten-to-twelve-grade-form/:id",
      name: "ten-to-twelve-grade-form",
      component: tenToTwelveGradeForm,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/ten-to-twelve-user-part/:id",
      name: "ten-to-twelve-user-part",
      component: tenToTwelveUserPart,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/ten-to-twelve-user-part-preview/:id",
      name: "ten-to-twelve-user-part-preview",
      component: tenToTwelveUserPartPreview,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/ten-to-twelve-iro-evaluation/:id",
      name: "ten-to-twelve-iro-evaluation",
      component: tenToTwelveIroEvaluation,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ten-to-twelve-iro-assessment/:id",
      name: "ten-to-twelve-iro-assessment",
      component: tenToTwelveIroAssessment,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ten-to-twelve-iro-assessment-preview/:id",
      name: "ten-to-twelve-iro-assessment-preview",
      component: tenToTwelveIroAssessmentPreview,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/ten-to-twelve-cro-evaluation/:id",
      name: "ten-to-twelve-cro-evaluation",
      component: tenToTwelveCroEvaluation,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/ten-to-twelve-cro-evaluation-form/:id",
      name: "ten-to-twelve-cro-evaluation-form",
      component: tenToTwelveCroEvaluationForm,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/ten-to-twelve-cro-evaluation-preview/:id",
      name: "ten-to-twelve-cro-evaluation-preview",
      component: tenToTwelveCroEvaluationPreview,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/ten-to-twelve-dossier-evaluation/:id",
      name: "ten-to-twelve-dossier-evaluation",
      component: tenToTwelveDossierEvaluation,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "/ten-to-twelve-form-acr-preview/:id",
      name: "ten-to-twelve-form-acr-preview",
      component: tenToTwelveFormAcrPreview,
      meta: { requiredAuth: true, roles: ["admin", "dosser"] },
    },


    // 13 to 16 form routes
    {
      path: "/thirteen-to-sixteen-grade-form/:id",
      name: "thirteen-to-sixteen-grade-form",
      component: thirteenToSixteenGradeForm,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/thirteen-to-sixteen-user-part/:id",
      name: "thirteen-to-sixteen-user-part",
      component: thirteenToSixteenUserPart,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/thirteen-to-sixteen-user-part-preview/:id",
      name: "thirteen-to-sixteen-user-part-preview",
      component: thirteenToSixteenUserPartPreview,
      meta: { requiredAuth: true, roles: ["applicant"] },
    },
    {
      path: "/thirteen-to-sixteen-iro-evaluation/:id",
      name: "thirteen-to-sixteen-iro-evaluation",
      component: thirteenToSixteenIroEvaluation,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/thirteen-to-sixteen-iro-assessment/:id",
      name: "thirteen-to-sixteen-iro-assessment",
      component: thirteenToSixteenIroAssessment,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/thirteen-to-sixteen-iro-assessment-preview/:id",
      name: "thirteen-to-sixteen-iro-assessment-preview",
      component: thirteenToSixteenIroAssessmentPreview,
      meta: { requiredAuth: true, roles: ["iro"] },
    },
    {
      path: "/thirteen-to-sixteen-cro-evaluation/:id",
      name: "thirteen-to-sixteen-cro-evaluation",
      component: thirteenToSixteenCroEvaluation,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/thirteen-to-sixteen-cro-evaluation-form/:id",
      name: "thirteen-to-sixteen-cro-evaluation-form",
      component: thirteenToSixteenCroEvaluationForm,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/thirteen-to-sixteen-cro-evaluation-preview/:id",
      name: "thirteen-to-sixteen-cro-evaluation-preview",
      component: thirteenToSixteenCroEvaluationPreview,
      meta: { requiredAuth: true, roles: ["cro"] },
    },
    {
      path: "/thirteen-to-sixteen-dossier-evaluation/:id",
      name: "thirteen-to-sixteen-dossier-evaluation",
      component: thirteenToSixteenDossierEvaluation,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "/thirteen-to-sixteen-form-acr-preview/:id",
      name: "thirteen-to-sixteen-form-acr-preview",
      component: thirteenToSixteenFormAcrPreview,
      meta: { requiredAuth: true, roles: ["dosser", "admin"] },
    },


    // Dossier Evaluation
    {
      path: "/dossier-evaluation/:id/:year/:form",
      name: "dossier-evaluation",
      component: DossierEvaluation,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
    {
      path: "/dossier-evaluation-preview/:id/:year/:form",
      name: "dossier-evaluation-preview",
      component: DossierEvaluationPreview,
      meta: { requiredAuth: true, roles: ["dosser"] },
    },
  ],
};

export default ACRRoute;
