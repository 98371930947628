<template>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <svg>
        <use href="@/assets/svg/icon-sprite.svg#notification"></use>
      </svg>
      <span v-if="totalNotification" class="badge rounded-pill badge-secondary">
        {{ totalNotification }}
      </span>
      <!-- <span v-else class="badge rounded-pill badge-secondary"> 0 </span> -->
    </div>
    <div class="onhover-show-div notification-dropdown">
      <h6 class="f-18 mb-0 dropdown-title">Notitications</h6>

      <ul>
        <li
          v-for="(notification, index) in logedInUserNotification"
          :key="index"
          :class="notification.viewed === false ? unseenBg : seen"
          @click="seenNotificationData(notification)"
        >
          <p>
            {{ notification.message }} {{ notification.viewed }}
            <span class="font-danger">
              {{ getHumanDate(notification.createdAt) }}
            </span>
          </p>
        </li>
      </ul>
      <!-- <div class="text-center py-4">
        <a class="f-w-700" href="#">Check all</a>
      </div> -->
    </div>
  </li>
</template>

<script>
import { getNotification, getLogedInUser } from "../../utils/auth";
import moment from "moment";
import { io } from "socket.io-client";
export default {
  name: "Notifications",
  data() {
    return {
      logedInUserNotification: [],
      totalNotification: 0,
      notification: false,
      getLogedInUser: JSON.parse(getLogedInUser()),
      seen: "b-l-success border-4",
      unseenBg: "bg-light text-black",
      socket: null,
      // shared worker
      broadcastChannel: "",
      worker: "",
    };
  },

  mounted() {
    // try {
    //   // shared worker
    //   this.worker = new SharedWorker("sworker.js");
    //   const id = uuid.v4();
    //   // Set initial web socket state to connecting. We'll modify this based
    //   // on events.
    //   let webSocketState = WebSocket.CONNECTING;
    //   this.worker.port.onmessage = (event) => {
    //     switch (event.data.type) {
    //       case "WSState":
    //         webSocketState = event.data.state;
    //         break;
    //       case "message":
    //         handleMessageFromPort(event.data);
    //         break;
    //     }
    //   };
    //   // Set up the broadcast channel to listen to web socket events.
    //   // This is also similar to above handler. But the handler here is
    //   // for events being broadcasted to all the tabs.
    //   this.broadcastChannel = new BroadcastChannel("WebSocketChannel");
    //   this.broadcastChannel.addEventListener("message", (event) => {
    //     // this.logedInUserNotification = event.data.data;
    //     if (event.data == "logout") {
    //       this.$router.push("/login");
    //     }
    //     switch (event.data.type) {
    //       case "WSState":
    //         webSocketState = event.data.state;
    //         break;
    //       case "message":
    //         //handleBroadcast(event.data);
    //         // console.log("Message paic", event.data.data);
    //         break;
    //       case "notification":
    //         this.getNotificationData();
    //         break;
    //     }
    //   });
    //   //Listen to broadcasts from server
    //   function handleBroadcast(data) {
    //     // console.log("This message is meant for everyone!");
    //     // console.log(data);
    //   }
    //   //Handle event only meant for this tab
    //   function handleMessageFromPort(data) {
    //     // console.log(`This message is meant only for user with id: ${id}`);
    //     // console.log(data);
    //   }
    //   //Use this method to send data to the server.
    //   function postMessageToWSServer(input) {
    //     // console.log(webSocketState, "Status");
    //     if (webSocketState === WebSocket.CONNECTING) {
    //       // console.log("Still connecting to the server, try again later!");
    //     } else if (
    //       webSocketState === WebSocket.CLOSING ||
    //       webSocketState === WebSocket.CLOSED
    //     ) {
    //       // console.log("Connection Closed!");
    //     } else {
    //       this.worker.port.postMessage({
    //         // Include the sender information as a uuid to get back the response
    //         from: id,
    //         data: input,
    //       });
    //     }
    //   }
    //   // Sent a message to server after approx 2.5 sec. This will
    //   // give enough time to web socket connection to be created.
    //   //setInterval(() => postMessageToWSServer("Initial message"), 1000);
    // } catch (error) {
    //   console.log(error);
    // }
  },
  created() {
    this.getNotificationCount();
    this.getAllNotification();
  },
  methods: {
    getNotificationCount() {
      this.$store.dispatch("notification/getNotificationCount").then((response) => {
        this.totalNotification = response.data;
      });
    },
    getAllNotification() {
      this.$store.dispatch("notification/getAllNotification").then((response) => {
        this.logedInUserNotification = response.data;
      });
    },
    // startWorker() {
    //   this.worker.port.postMessage({
    //     // Include the sender information as a uuid to get back the response
    //     type: "tata",
    //   });
    // },

    getHumanDate: function (date) {
      // return moment(date).locale("bn-BD").format("YYYY-MM-DD HH:mm:ss");
      return moment(date).locale("en-US").format("YYYY-MM-DD hh:mm A");
    },
  },
};
</script>
