<template>
  <div class="m-0 p-0">
    <div class="loader-wrapper" v-if="loading">
      <img
        class="loader-index"
        height="150"
        width="300"
        src="./assets/images/ict-acr-images/loader/loader.png"
      />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import {
  getLogedInUser,
  setLogedInUserInfo,
  setNotification,
  setUserRole,
  getLogedInUserInfo
} from "./utils/auth";
export default {
  name: "App",
  data() {
    return {
      loading: true,
      logedInUser: JSON.parse(getLogedInUser()),
      userInfo: JSON.parse(getLogedInUserInfo()),
      role: {
        Admin: false,
        hr: false,
        User: false,
        IRO: false,
        CRO: false,
        access_log: false,
        applicant: false,
        Reporter_assign: false,
        Role: false,
      },
    };
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.loading = !this.loading;
      }, 1000);
      this.loading = !this.loading;
    },
  },
  mounted() {
    this.loading = false;
    // if(this.$store.state.userStore.loggedInUser && !this.$store.state.userStore.loggedInUser.id){
    //   if(this.userInfo){
    //     this.getInfo();
    //   }
      
    // }
  },
  methods: {
    async getInfo() {
      const userDetail = await this.$store.dispatch(
        "userStore/getInfo");
      console.log(userDetail);
    },
  },
};
</script>
