<template>
  <div>    
    <div v-if="tree.id" class="main-list">
      <div :class="folderclass" @click.prevent="toggle">
        <img
          v-if="open && tree.type !== 'root'"
          class="img-1"
          src="@/assets/images/check-1.png"
        />
        <img
          v-else-if="tree.type !== 'root' && !open"
          class="img-1"
          src="@/assets/images/close.png"
        />
        <div class="step-1">
          <img class="img-2" key="1" src="@/assets/images/office.svg" />
          <span class="fa">{{ tree.name_bn }}</span>
        </div>
      </div>
      <img
        
        class="img-3"
        key="1"
        src="@/assets/images/user-list.svg"
        @click.prevent="showModal(tree)"
      />
    </div>
    <div v-else>
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tree: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
      this.$emit("getOpenClose", this.open);
    },
    showModal(tree) {
      this.$store.dispatch("appConfig/showModal", { modal: true, tree});
    },
  },
  computed: {
    folderclass() {
      let foldclass = "close";
      switch (this.open) {
        case false:
          foldclass = "close";
          break;
        case true:
          foldclass = "open";
          break;
      }
      return { [foldclass]: true };
    },
  },
};
</script>

<style scoped>
.main-list {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.img-1 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: -2.8%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: 2px solid #fff;
  border-radius: 50%;
}
.line {
  width: 80px;
  height: 4px;
  background: rgba(121, 134, 159, 0.6);
}

.step-1 {
  display: flex;
  align-items: center;
  border: 3px solid rgba(1, 24, 94, 1);
  padding: 5px 18px;
  border-radius: 6px;
  cursor: pointer;
  margin: 15px 0px;
}
.step-1 .img-2 {
  width: 35px;
  height: 55px;
  margin-right: 10px;
}
.step-1 span {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  line-height: 23.34px;
}
.img-3 {
  width: 45px;
  height: 65px;
  margin-left: 10px;
}
</style>
