const User = () => import("@/pages/users/index");
const userCreate = () => import("@/pages/users/users-component/create");
const userUpdate = () => import("@/pages/users/users-component/update");
const userView = () => import("@/pages/users/users-component/view");
const userLogs = () => import("@/pages/users/users-component/user-logs");
const userProfile = () => import("@/pages/users/profile");
const userProfileEdit = () => import("@/pages/users/profile-edit");

import Body from "@/components/body";
const usersRoute = {
  path: "/users",
  component: Body,
  children: [
    {
      path: "",
      name: "User-List",
      component: User,
      meta: {
        requiredAuth: true,
        roles: ["admin", "hr"]
      },
    },
    {
      path: "/create-user",
      name: "create-user",
      component: userCreate,
      meta: {
        requiredAuth: true,
        roles: ["admin", "hr"]
      },
    },
    {
      path: "/user-view/:id",
      name: "user-view",
      meta: {
        requiredAuth: true,
        roles: ["admin", "hr"]
      },
      component: userView,
    },
    {
      path: "/user-edit/:id",
      name: "user-edit",
      meta: {
        requiredAuth: true,
        roles: ["admin", "hr"]
      },
      component: userUpdate,
    },
    {
      path: "/profile",
      name: "profile",
      component: userProfile,
      meta: {
        requiredAuth: true,
        roles: ["admin", "user", "dosser", "hr", "applicant", "iro", "cro", "role", "reporter assign", "access log"],
      },
    },
    {
      path: "/profile-update",
      name: "profile-update",
      component: userProfileEdit,
      meta: {
        requiredAuth: true,
        roles: ["admin", "user", "dosser", "hr", "applicant", "iro", "cro", "role", "reporter assign", "access log"],
      },
    },
  ],
};

export default usersRoute;
