import {
    getAccessLogs,
  } from "@/api/access-log";
  const actions = {
    getAccessLogs({ commit }, query) {
      return new Promise((resolve, reject) => {
        getAccessLogs(query)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  };
  
  export default {
    namespaced: true,
    actions,
  };
  