import { createStore } from "vuex";

import layout from "./modules/layout";
import menu from "./modules/menu";
import contact from "./modules/contact";
import common from "./modules/common";
import bootsrap from "./modules/bootsrap";
import language from "./modules/language";
import auth from "./modules/auth";
import userStore from "./modules/userStore";
import acrStore from "./modules/acrStore";
import appConfig from "./app-config";
import designation from "./modules/hr-settings/deisgnation";
import section from "./modules/hr-settings/section";
import wing from "./modules/hr-settings/wing";
import branch from "./modules/hr-settings/branch";
import salaryScale from "./modules/hr-settings/salary-scale";
import questions from "./modules/questions";
import confStore from "./modules/hr-settings/confStore";
import permission from "./modules/permission";
import accessLog from "./modules/access-log";
import notification from "./modules/notification";
import grade from "./modules/grade";

import reporter from "./modules/hr-settings/reporter";
import rolePermission from "./modules/hr-settings/rolePermission";
import getters from "./getters";

export default createStore({
  state: { langIcon: "", langLangauge: "", isActive: false },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
    ...getters,
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      state.langIcon = payload.icon || "flag-icon-us";
      state.langLangauge = payload.id || "EN";
    },
    change(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    appConfig,
    layout,
    menu,
    language,
    common,
    contact,
    bootsrap,
    auth,
    userStore,
    acrStore,
    designation,
    section,
    reporter,
    rolePermission,
    wing,
    branch,
    salaryScale,
    questions,
    grade,
    confStore,
    permission,
    accessLog,
    notification,
  },
});
