import request from "../utils/request";

// submit ACR form
export function submitAcrForm(data) {
  return request({
    url: "acr-form/operation",
    method: "post",
    data,
  });
}

// get pending acr list
export function getPendingAcrList(query) {
  return request({
    url: "/dossier/evaluation/list",
    method: "get",
    params: query,
  });
}

// get evaluated acr list
export function getEvaluatedAcrList(query) {
  return request({
    url: "/dossier/evaluated/list",
    method: "get",
    params: query,
  });
}

// get pending dossier list
export function getUsersList() {
  return request({
    url: "/dossier/helper",
    method: "get",
  });
}

// get  Evaluation ACR Detail
export function getEvaluationACRDetail(query) {
  return request({
    url: "/dossier/evaluation/report",
    method: "get",
    params: query,
  });
}

// get last 3 years acr avarage marks
export function getLastThreeYearAcrMarks(query) {
  return request({
    url: "/dossier/average/mark",
    method: "get",
    params: query,
  });
}

// dossier evaluation
export function submitDossierForm(data) {
  return request({
    url: "/dossier/evaluate/report",
    method: "post",
    data,
  });
}

// get evaluated acr detail
export function getEvaluatedAcrDetail(query) {
  return request({
    url: "/dossier/evaluated",
    method: "get",
    params: query,
  });
}
