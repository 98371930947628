<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
        v-if="userinfo.profileImage !== null"
        class="rounded border"
        :src="
          userinfo.profileImage
            ? $store.state.appConfig.imageBaseUrl + userinfo.profileImage
            : `https://ui-avatars.com/api/?name=${user.englishName}`
        "
        height="25"
        width="25"
        alt="profile image"
      />
      <img
        v-else
        src="../../assets/images/ict-acr-images/avatar/avatar.png"
        alt="upload-img"
        height="25"
        width="25"
      />
      <div class="media-body">
        <span>
          {{ userinfo.banglaName }}<i class="middle fa fa-angle-down"></i
        ></span>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="`/profile`"
          ><vue-feather type="user"></vue-feather
          ><span>প্রোফাইল </span></router-link
        >
      </li>
      <li>
        <a @click="logout"
          ><vue-feather type="log-in"></vue-feather><span>লগ আউট</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>
import { getLogedInUser, getLogedInUserInfo } from "@/utils/auth";
export default {
  name: "Profile",
  data() {
    return {
      user: JSON.parse(getLogedInUser()),

      userinfo: JSON.parse(getLogedInUserInfo()),
    };
  },
  methods: {
    logout: function () {
      localStorage.removeItem("User");
      localStorage.removeItem("UserInfo");
      localStorage.removeItem("Admin-Token");
      localStorage.removeItem("Notification");
      // this.$socket.disconnect();
      this.$router.replace("/auth/login");
    },
  },
};
</script>
