// section routes
const getSection = () => import("../../../pages/hr-settings/section");
const createSection = () =>
  import("../../../pages/hr-settings/section/create-section.vue");
const viewSection = () =>
  import("../../../pages/hr-settings/section/update-section.vue");

import Body from "../../../components/body";
const sections = {
  path: "/sections",
  component: Body,
  meta: { title: "Section", roles: ["hr", "admin"] },
  children: [
    // section
    {
      path: "",
      name: "/sections-index",
      component: getSection,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/create-section",
      name: "create-section",
      component: createSection,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
    {
      path: "/section-view/:id",
      name: "section-view",
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
      component: viewSection,
    },
  ],
};

export default sections;
