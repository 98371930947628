// wing routes
const getBranch = () => import("../../../pages/hr-settings/configurations");

import Body from "../../../components/body";
const wings = {
  path: "/configurations",
  component: Body,
  meta: { title: "Configurations", roles: ["hr", "admin"] },
  children: [
    // wing
    {
      path: "",
      name: "/branch-index",
      component: getBranch,
      meta: { requiredAuth: true, roles: ["hr", "admin"] },
    },
  ],
};

export default wings;
