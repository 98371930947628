<template>
  <div>
    <ul class="contents">
      <li v-for="(child, index) in children" :key="index">
        <tree-folder v-if="child.children" :folder="child"></tree-folder>
        <tree-content-component v-else :tree="child"></tree-content-component>
      </li>
    </ul>
  </div>
</template>

<script>
import TreeContentComponent from "./tree-content-component.vue";
export default {
  components: {
    TreeContentComponent,
  },
  props: {
    children: {
      type: [Object, Array],
    },
  },
};
</script>

<style scoped>

</style>
